import React from 'react';
import { applyDarkTheme } from '../../utils/css';
import bannerImg from '../../images/banner_img.png';
import featureIcon1 from '../../images/sales-pipeline-1.svg';
import featureIcon2 from '../../images/orders.svg';
import featureIcon3 from '../../images/inventory-1.svg';
import reviewImg from '../../images/review_bg.png';
import PricingOptions from '../pricing/price-options';
import theOfficeSpaceLogo from '../../images/the_office_space_logo.png';

class Home extends React.PureComponent {
  componentDidMount = () => {
    applyDarkTheme();
  };
  render() {
    return (
      <div>
        <section className="banner_part">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-5">
                <div className="banner_img d-none d-lg-block">
                  <img src={bannerImg} alt />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="banner_text">
                  <div className="banner_text_iner">
                    <h1>Small business software to help you thrive</h1>
                    <p>
                      Growing a small business is not easy. Excelera helps small
                      businesses manage revenue, sales, cashflow and inventory
                      so you can focus on growing your business.
                    </p>
                    <a href="https://signup.excelera.com.au" className="btn_2">
                      try for free
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="use_sasu section_padding">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon1} className="feature-icon" alt />
                    <h4>Sales Pipeline</h4>
                    <div>
                      Get your sales team organised, and then watch your sales
                      grow.
                      <ul className="feature-list">
                        <li>Track new sales leads</li>
                        <li>Close deals by stages</li>
                        <li>Progress sales towards closing</li>
                        <li>Highly visual pipeline</li>
                        <li>Quote generator</li>
                        <li>Customise pipeline stages</li>
                        <li>Built in CRM</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon2} className="feature-icon" alt />
                    <h4>Manage Orders and Invoices</h4>
                    <div>
                      Manage your customer wins and keep them up to date on
                      their order status.
                      <ul className="feature-list">
                        <li>Create invoices fast</li>
                        <li>Track overdue amounts</li>
                        <li>Generate quotes</li>
                        <li>Be cashflow positive</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon3} className="feature-icon" alt />
                    <h4>Inventory Management</h4>
                    <div>
                      Keep track of inventory to ensure you have what your
                      customers want, and minimize waste.
                      <ul className="feature-list">
                        <li>Real time inventory management</li>
                        <li>Works with invoice creator</li>
                        <li>Manage returns and warranties</li>
                        <li>
                          Sales pipeline integration. Know what you have in
                          stock when quoting
                        </li>
                        <li>Barcode compatibility</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="learn-more-padding">
                  <a href="/#/features" className="btn_2">
                    learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pricing_part section_padding home_page_pricing">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section_title text-center">
                  <h2>Simple Pricing</h2>
                  <p>
                    Sign up and use for free when you are starting out. Then
                    upgrade your plan as your business grows.
                  </p>
                </div>
              </div>
            </div>
            <PricingOptions />
          </div>
        </section>
        <section className="review_part padding_bottom">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="review_img">
                  <img src={reviewImg} alt />
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="review_part_text">
                  <h1>Customer Testimonial</h1>
                  <p className="testimonial-text">
                    “Thanks to Excelera we have been able to improve our
                    business operations as well as our customer experience
                    drastically. We were able to replace several paid software
                    applications and lots of spreadsheets with Excelera. Our
                    employees and clients now have access to business
                    information anywhere and anytime.”
                  </p>
                  <div className="testimonial-logo-container">
                    <img
                      className="testimonial-logo"
                      src={theOfficeSpaceLogo}
                      alt
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Home;
