import React from 'react';
import Breadcrumb from '../layout/breadcrumb';
import { applyLightTheme } from '../../utils/css';
import singleSourceOfTruth from '../../images/single-source-truth.svg';
import oneSoftware from '../../images/one-software.svg';
import simplifyYourWorkflow from '../../images/workflow.svg';
import exceedCustomerExpectations from '../../images/customer-expectations.svg';
import insights from '../../images/insights.svg';
import getOrganised from '../../images/get-organised.svg';

class WhyChooseExcelera extends React.PureComponent {
  componentDidMount = () => {
    document.getElementById('logoDark').style.display = 'none';
    document.getElementById('logoWhite').style.display = 'block';
    applyLightTheme();
  };
  render() {
    return (
      <div>
        <Breadcrumb title="Why Excelera" section="why" />
        <section className="use_sasu section_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 pad-bottom-30">
                <h1>Why Excelera?</h1>
                <p>
                  Excelera is built for small business. An Australian based
                  company, Excelera was inspired by helping small businesses
                  succeed.
                </p>
                <p>
                  It’s founders, run successful small businesses themselves,
                  understand the dedication, challenges, pain points, the highs
                  and lows in running a small business.
                </p>
                <p>
                  While small businesses are driven by the passion and vision of
                  the business owner, most often they forget to put in place an
                  integrated eco-system to nurture the business.
                </p>
                <p>
                  We have developed this integrated eco-system that eliminates
                  double up in efforts often manifests in small businesses.
                </p>
                <p>
                  Our goal is to help Australia’s large base of small businesses
                  get it right from the beginning and shave years in getting
                  their processes right.
                </p>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <img src={singleSourceOfTruth} className="why-icon" alt />
                  </span>
                  <div className="media-body">
                    <h3>A Single Source of Truth</h3>
                    <p>
                      Get straight to the truth about your business when you
                      need to.
                    </p>
                    <p>
                      No need to login to different systems to find the
                      information you want.
                    </p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <img src={oneSoftware} className="why-icon" alt />
                  </span>
                  <div className="media-body">
                    <h3>One Software</h3>
                    <p>
                      Most businesses need over 8 software applications to run.
                      Excelera can replace all of them.
                    </p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <img src={simplifyYourWorkflow} className="why-icon" alt />
                  </span>
                  <div className="media-body">
                    <h3>Simplify your Workflow</h3>
                    <p>
                      Running a small business is hard work but it does not have
                      to be complicated.
                    </p>
                    <p>
                      Excelera lets create work flows to suit your business.
                      This ensures consistency and ultimately customer
                      satisfaction.
                    </p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <img
                      src={exceedCustomerExpectations}
                      className="why-icon"
                      alt
                    />
                  </span>
                  <div className="media-body">
                    <h3>Exceed Customer Expectations</h3>
                    <p>
                      Excelera is a scalable platform that can grow with your
                      business.
                    </p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <img src={insights} className="why-icon" alt />
                  </span>
                  <div className="media-body">
                    <h3>Insights means better instincts</h3>
                    <p>
                      Small business does not have to run on instinct anymore.
                      Data from Excelera gives your business the insights it
                      needs.
                    </p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <img src={getOrganised} className="why-icon" alt />
                  </span>
                  <div className="media-body">
                    <h3>Get Organised</h3>
                    <p>
                      Everything in its place, and a place for everything. Get
                      your business data organised and never struggle to find
                      the information you need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default WhyChooseExcelera;
