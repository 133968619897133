import React from 'react';
import Breadcrumb from '../layout/breadcrumb';
import { applyLightTheme } from '../../utils/css';

import featureIcon1 from '../../images/sales-pipeline-1.svg';
import featureIcon2 from '../../images/orders.svg';
import featureIcon3 from '../../images/inventory-1.svg';
import featureIcon4 from '../../images/booking-1.svg';
import featureIcon5 from '../../images/timesheets-1.svg';
import featureIcon6 from '../../images/projects-1.svg';

class Features extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    applyLightTheme();
  };

  render() {
    return (
      <div>
        <Breadcrumb title="Features" section="features" />
        <section className="use_sasu section_padding">
          <div className="container">
            <div className="row pad-bottom-30 justify-content-center">
              <div className="col-12 text-center">
                <h1>
                  An Enterprise Resource Planning application for small business
                </h1>
                <p>
                  Running a small business is not easy. That's why we created
                  Excelera, to help small businesses take control of their
                  operations, by giving them the tools that were once the domain
                  of large enterprises.
                </p>
              </div>
            </div>
            <div className="row feature-row justify-content-center">
              <div className="col-lg-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon1} alt />
                    <h4>Sales Pipeline</h4>
                    <div>
                      Get your sales team organised and ready to conquer the
                      world. Excelera provides a world class CRM with an easy to
                      use sales management system. This lets you track leads,
                      generate new quotes, and gives you detailed statistics on
                      your lead generation and sales team.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon2} alt />
                    <h4>Orders and Invoices</h4>
                    <div>
                      Excelera has a comprehensive order management and
                      invoicing system that ensures that you can efficiently
                      organise your team to fulfil orders and keep your
                      customers up to date on their order statuses.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row feature-row justify-content-center">
              <div className="col-lg-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon3} alt />
                    <h4>Inventory Management</h4>
                    <div>
                      Having real time inventory managment, lets you and your
                      customers know what you have in stock. With statistics on
                      best selling products, your run rates and easy to use
                      returns and searching of serial numbers, you can keep your
                      inventory in tune with your sales to maximize profits.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon4} alt />
                    <h4>Booking System</h4>
                    <div>
                      Excelera includes an advanced booking system, with a
                      comprehensive API that lets you build custom apps. Create
                      different types of assets and make them bookable.
                      <p>
                        This lets you manage assets like rooms or even people.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row feature-row justify-content-center">
              <div className="col-lg-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon5} alt />
                    <h4>Timesheets</h4>
                    <div>
                      Keep track of your staff hours and what they are working
                      on. Easy to enter timesheet entry makes it easy to
                      employees to enter and allocate their work.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="single_feature">
                  <div className="single_feature_part">
                    <img src={featureIcon6} alt />
                    <h4>Project Management</h4>
                    <div>
                      Get your team co-ordinated and in sync with a
                      comprehensive projects management tool that tracks, costs,
                      time and tasks as well as notes.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Features;
