import React from 'react';
import pricingIcon1 from '../../images/free-1.svg';
import pricingIcon2 from '../../images/standard-1.svg';
import pricingIcon3 from '../../images/diamond-1.svg';

class PricingOptions extends React.PureComponent {
  render() {
    return (
      <div className="row justify-content-center">
        <div className="col-lg-4 col-sm-6">
          <div className="single_pricing_part">
            <img src={pricingIcon1} alt />
            <p>Free</p>
            <h3>
              $0 <span>/month/user</span>
            </h3>
            <ul>
              <li>Up to 3 users</li>
              <li>
                <b>Applications include:</b>
                <ul className="applications-list">
                  <li>Sales pipeline</li>
                  <li>Invoice creator</li>
                  <li>Inventory tracker</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="single_pricing_part">
            <img src={pricingIcon2} alt />
            <p>Standard</p>
            <h3>
              $15 <span>/month/user</span>
            </h3>
            <ul>
              <li>First 3 users are free</li>
              <li>Only pay for the 4th user onwards</li>
              <li>
                <b>Applications include:</b>
                <ul className="applications-list">
                  <li>Sales pipeline</li>
                  <li>Invoice creator</li>
                  <li>Inventory tracker</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="single_pricing_part">
            <img src={pricingIcon3} alt />
            <p>Premium</p>
            <h3>
              $30 <span>/month/user</span>
            </h3>
            <ul>
              <li>Application support</li>
              <li>
                <b>Applications include:</b>
                <ul className="applications-list">
                  <li>Sales pipeline</li>
                  <li>Invoice creator</li>
                  <li>Inventory tracker</li>
                  <li>Project management</li>
                  <li>Asset management</li>
                  <li>Booking system</li>
                  <li>Accounting system</li>
                  <li>Timesheets</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default PricingOptions;
