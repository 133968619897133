import React from 'react';
import {Link} from 'react-router-dom';

class Breadcrumb extends React.PureComponent {
  render() {
    return (
      <section className="breadcrumb breadcrumb_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb_iner text-center">
                <div className="breadcrumb_iner_item">
                  <h2>{this.props.title}</h2>
                  <h5>
                    <Link to="/" className="breadcrumb-link">
                      home
                    </Link>
                    <span></span>
                    {this.props.section}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Breadcrumb;
