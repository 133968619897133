import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {mapStateToProps} from './redux/utils';
import Layout from './components/layout';
import Home from './components/home';
import PricingOptions from './components/pricing';
import ContactUs from './components/contact';
import Features from './components/features';
import WhyChooseExcelera from './components/why';

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout>
        <HashRouter>
          <Switch>
            <Route path="/features" component={Features} />
            <Route path="/pricing" component={PricingOptions} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/why" component={WhyChooseExcelera} />
            <Route component={Home} />
          </Switch>
        </HashRouter>
      </Layout>
    );
  }
}

export default connect(mapStateToProps)(App);
