import React from 'react';
import PricingOptions from './price-options';
import Breadcrumb from '../layout/breadcrumb';
import { applyLightTheme } from '../../utils/css';

class PricingPlans extends React.PureComponent {
  componentDidMount = () => {
    applyLightTheme();
  };
  render() {
    return (
      <div>
        <Breadcrumb title="Pricing Plans" section="pricing" />
        <section className="pricing_part section_padding home_page_pricing">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section_title text-center">
                  <h2>Simple Pricing</h2>
                  <p>
                    Sign up and use for free when you are starting out. Then
                    upgrade your plan as your business grows.
                  </p>
                </div>
              </div>
            </div>
            <PricingOptions />
          </div>
        </section>
      </div>
    );
  }
}
export default PricingPlans;
