/**
 * REDUX Constants
 */
export const SEARCH_ASSETS = 'SEARCH_ASSETS';
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_STARTED = 'SEARCH_STARTED';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const GET_PHYSIO_DETAILS_START = 'GET_PHSYIO_DETAILS_START';
export const GET_PHYSIO_DETAILS_SUCCESS = 'GET_PHYSIO_DETAILS_SUCCESS';
export const GET_PHYSIO_DETAILS_FAILURE = 'GET_PHYSIO_DETAILS_FAILURE';
export const SET_USER_FIRST_NAME = 'SET_USER_FIRST_NAME';
export const SET_USER_MIDDLE_NAME = 'SET_USER_MIDDLE_NAME';
export const SET_USER_LAST_NAME = 'SET_USER_LAST_NAME';
export const SET_USER_MOBILE = 'SET_USER_MOBILE';
export const SET_USER = 'SET_USER';
export const SET_OOBCODE = 'SET_OOBCODE';
export const SET_CALENDAR_DIALOG_STATUS = 'SET_CALENDAR_DIALOG_STATUS';
export const SET_CALENDAR_SELECTION = 'SET_CALENDAR_SELECTION';
export const CONFIRM_BOOKING_START = 'CONFIRM_BOOKING_START';
export const CONFIRM_BOOKING_SUCCESS = 'CONFIRM_BOOKING_SUCCESS';
export const CONFIRM_BOOKING_FAILURE = 'CONFIRM_BOOKING_FAILURE';
export const GET_NODES_START = 'GET_NODES_START';
export const GET_NODES_SUCCESS = 'GET_NODES_SUCCESS';
export const GET_NODES_FAILURE = 'GET_NODES_FAILURE';
export const GET_BOOKINGS_START = 'GET_BOOKINGS_START';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAILURE = 'GET_BOOKINGS_FAILURE';

/**
 * Config Constants
 */
export const SERVER_URL = 'http://localhost:5000';
export const IDENTITY_SERVER = 'http://localhost:3003';
export const METADATA_SERVER = 'http://localhost:3008';
export const DEFAULT_USER = {
  name: {
    first: '',
    middle: '',
    last: '',
  },
  mobileNumber: '',
  email: '',
};
