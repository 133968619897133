import React from 'react';
import Breadcrumb from '../layout/breadcrumb';
import Axios from 'axios';
import * as R from 'ramda';
import ErrorAlert from '../misc/error';
import Modal from '../misc/modal';
import { applyLightTheme } from '../../utils/css';
class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      name: '',
      email: '',
      errors: '',
      btnText: 'Send Message',
    };
  }

  componentDidMount = () => {
    applyLightTheme();
  };

  messageChanged = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  nameChanged = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  emailChanged = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  submitForm = async () => {
    this.setState({
      btnText: 'Sending Message...',
    });

    const data = R.pick(['message', 'name', 'email'], this.state);
    const modal = document.getElementById('modal');
    modal.classList.add('show');
    try {
      const response = await Axios({
        method: 'post',
        data,
        url: '/api/v1/send-message',
      });
      this.setState({
        btnText: 'Send Message',
      });
    } catch (err) {
      this.setState({
        errors: err.response.data.message,
        btnText: 'Send Message',
      });
    }
  };

  render() {
    return (
      <div>
        <Breadcrumb title="Contact Us" section="contact" />
        <section className="contact-section section_padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="contact-title">Get in Touch</h2>
              </div>
              <div className="col-lg-8">
                <form className="form-contact contact_form">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          className="form-control w-100"
                          name="message"
                          id="message"
                          cols="30"
                          rows="9"
                          placeholder="Let us know how we can help..."
                          onChange={this.messageChanged}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          name="name"
                          id="name"
                          type="text"
                          placeholder="Your name"
                          onChange={this.nameChanged}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <input
                        className="form-control"
                        name="email"
                        id="email"
                        type="text"
                        placeholder="Email address"
                        onChange={this.emailChanged}
                      />
                    </div>
                    <div className="col-12">
                      <ErrorAlert message={this.state.errors} />
                    </div>
                    <div className="col-12">
                      <div className="form-group mt-3">
                        <a
                          className="genric-btn primary"
                          onClick={this.submitForm}
                        >
                          {this.state.btnText}
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-4">
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <i className="ti-home"></i>
                  </span>
                  <div className="media-body">
                    <h3>44 Princess Street</h3>
                    <p>Brighton-Le-Sands NSW 2216</p>
                  </div>
                </div>
                <div className="media contact-info">
                  <span className="contact-info__icon">
                    <i className="ti-email"></i>
                  </span>
                  <div className="media-body">
                    <h3>sales@excelera.com.au</h3>
                    <p>Send us your query anytime!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal></Modal>
      </div>
    );
  }
}

export default ContactUs;
