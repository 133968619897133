const React = require('react');

class Modal extends React.PureComponent {
  render() {
    return (
      <div
        className="modal fade"
        role="dialog"
        tabIndex="-1"
        aria-labelledby="Modal"
        aria-hidden="true"
        id="modal"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5>Error!</h5>
            </div>
            <div className="modal-body"></div>
          </div>
        </div>
      </div>
    );
  }
}
module.exports = Modal;
