import moment from 'moment';

import {
  SEARCH_STARTED,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  GET_PHYSIO_DETAILS_START,
  GET_PHYSIO_DETAILS_SUCCESS,
  GET_PHYSIO_DETAILS_FAILURE,
  SET_USER_FIRST_NAME,
  SET_USER_MIDDLE_NAME,
  SET_USER_LAST_NAME,
  SET_USER_MOBILE,
  SET_USER,
  SET_OOBCODE,
  SET_CALENDAR_SELECTION,
  SET_CALENDAR_DIALOG_STATUS,
  CONFIRM_BOOKING_START,
  CONFIRM_BOOKING_SUCCESS,
  CONFIRM_BOOKING_FAILURE,
  GET_NODES_START,
  GET_NODES_SUCCESS,
  GET_NODES_FAILURE,
  GET_BOOKINGS_START,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAILURE,
} from '../../constants';

const cloneState = (state) => JSON.parse(JSON.stringify(state));

const initBookings = (bookings) =>
  bookings.map((booking) => {
    booking.start = moment(booking.start).toDate();
    booking.end = moment(booking.end).toDate();
    return booking;
  });

const rootReducer = function(state, {type, payload}) {
  const nstate = cloneState(state);
  switch (type) {
    case SEARCH_STARTED:
      nstate.search = {
        data: [],
        fetching: true,
        fetched: false,
        error: false,
        count: 0,
      };
      return nstate;
    case SEARCH_SUCCESS:
      nstate.search = {
        data: payload,
        fetching: false,
        fetched: true,
        error: false,
      };
      return nstate;
    case SEARCH_FAILURE:
      nstate.search.fetching = false;
      nstate.search.error = payload.error;
      return nstate;
    case GET_PHYSIO_DETAILS_START:
      nstate.asset = {
        fetching: true,
        fetched: false,
        error: false,
      };
      return nstate;
    case GET_PHYSIO_DETAILS_SUCCESS:
      nstate.asset = {
        details: payload.asset,
        bookings: initBookings(payload.bookings),
        images: payload.images,
        fetching: false,
        fetched: true,
        error: false,
      };
      console.log(nstate.asset);
      return nstate;
    case GET_PHYSIO_DETAILS_FAILURE:
      nstate.asset = {
        error: payload.error,
        fetching: false,
        fetched: false,
      };
      return nstate;
    case SET_USER_FIRST_NAME:
      nstate.user.name.first = payload;
      return nstate;
    case SET_USER_MIDDLE_NAME:
      nstate.user.name.middle = payload;
      return nstate;
    case SET_USER_LAST_NAME:
      nstate.user.name.last = payload;
      return nstate;
    case SET_USER_MOBILE:
      nstate.user.mobileNumber = payload;
      return nstate;
    case SET_USER:
      nstate.user = payload;
      return nstate;
    case SET_OOBCODE:
      if (nstate.user.auth) {
        nstate.user.auth.oobCode = payload;
      } else {
        nstate.user.auth = {
          oobCode: payload,
        };
      }
      return nstate;
    case SET_CALENDAR_DIALOG_STATUS:
      nstate.calendar.dialogIsOpen = payload;
      return nstate;
    case SET_CALENDAR_SELECTION:
      nstate.calendar.selected = payload;
      return nstate;
    case CONFIRM_BOOKING_START:
      nstate.confirmBooking = {
        fetching: true,
        fetched: false,
        error: false,
        response: {},
      };
      return nstate;
    case CONFIRM_BOOKING_SUCCESS:
      nstate.confirmBooking = {
        fetching: false,
        fetched: true,
        error: false,
        response: payload,
      };
      nstate.calendar = {
        dialogIsOpen: false,
        selected: {},
      };
      return nstate;
    case CONFIRM_BOOKING_FAILURE:
      nstate.confirmBooking = {
        fetching: false,
        fetched: true,
        error: payload.error,
        response: {},
      };
      return nstate;
    case GET_NODES_START:
      nstate.nodes = {
        fetching: true,
        fetched: false,
        error: false,
        list: [],
      };
      return nstate;
    case GET_NODES_SUCCESS:
      nstate.nodes = {
        fetching: false,
        fetched: true,
        error: false,
        list: payload.nodes,
      };
      return nstate;
    case GET_NODES_FAILURE:
      nstate.nodes = {
        fetching: false,
        fetched: false,
        error: payload.error,
        list: [],
      };
      return nstate;
    case GET_BOOKINGS_START:
      return nstate;
    case GET_BOOKINGS_SUCCESS:
      nstate.asset.bookings = initBookings(payload.bookings[0]);
      return nstate;
    case GET_BOOKINGS_FAILURE:
      nstate.asset.error = payload.error;
      return nstate;
    default:
      return nstate;
  }
};

export default rootReducer;
