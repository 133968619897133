const applyLightTheme = () => {
  document.getElementById('navbar').classList.add('navbar-light');
  document.getElementById('navbar').classList.remove('navbar-dark');
};

const applyDarkTheme = () => {
  document.getElementById('navbar').classList.add('navbar-dark');
  document.getElementById('navbar').classList.remove('navbar-light');
};

module.exports = {
  applyLightTheme,
  applyDarkTheme,
};
