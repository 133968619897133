import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import rootReducer from '../reducers';
import {DEFAULT_USER} from '../../constants';

const cookies = new Cookies();

const getUser = () => {
  const accessToken = cookies.get('accessToken');
  if (accessToken) {
    const xJwt = jwtDecode(accessToken);
    if (xJwt) {
      return xJwt;
    }
  }
  return DEFAULT_USER;
};

const INITIAL_STATE = {
  search: {
    data: [],
    fetching: false,
    fetched: false,
    error: false,
    count: 0,
  },
  sf: '',
  asset: {
    fetching: true,
    fetched: false,
    error: false,
    details: {},
    bookings: [],
    images: [],
  },
  user: getUser(),
  calendar: {
    selected: {},
    dialogIsOpen: false,
  },
  confirmBooking: {
    fetching: true,
    fetched: false,
    error: false,
    response: {},
  },
  nodes: {
    fetching: true,
    fetched: false,
    error: false,
    list: [],
  },
};

console.log(INITIAL_STATE);
const store = createStore(rootReducer, INITIAL_STATE, applyMiddleware(thunk));
export default store;
