import 'babel-polyfill';
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import App from './app';

// Import CSS files
import './css/bootstrap.min.css';
import './css/all.css';
import './css/style.css';
// import './css/flaticon.css';
import './css/aos.css';
import './css/themify-icons.css';
import './css/animate.css';

render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('app')
);
