const React = require('react');

class ErrorAlert extends React.PureComponent {
  render() {
    if (this.props.message) {
      return <div className="alert alert-danger">{this.props.message}</div>;
    } else {
      return null;
    }
  }
}

module.exports = ErrorAlert;
