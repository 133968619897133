import React from 'react';
import logoWhite from '../../images/logo-white.svg';
import logo from '../../images/logo.svg';

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuIsVisible: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    // do not use document.body.scrollTop that seems to return 0
    // https://stackoverflow.com/questions/12788487/document-scrolltop-always-returns-0
    let scrollTop = document.scrollingElement.scrollTop;
    const menu = document.getElementById('mainMenu');
    if (scrollTop > 50) {
      menu.classList.add('menu_fixed', 'animated', 'fadeInDown');
    } else {
      menu.classList.remove('menu_fixed', 'animated', 'fadeInDown');
    }
  };

  toggleMenu = () => {
    const menuList = document.getElementById('navbarSupportedContent');
    if (this.state.menuIsVisible) {
      menuList.classList.remove('show');
      this.setState({
        menuIsVisible: false,
      });
    } else {
      menuList.classList.add('show');
      this.setState({
        menuIsVisible: true,
      });
    }
  };

  render() {
    return (
      <div className="main">
        <header className="main_menu" id="mainMenu">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav
                  id="navbar"
                  className="navbar navbar-expand-lg navbar-dark"
                >
                  <a id="logoWhite" className="navbar-brand main-logo" href="/">
                    <img className="logo" src={logoWhite} alt="logo" />
                  </a>
                  <a id="logoDark" className="navbar-brand dark-logo" href="/">
                    <img className="logo" src={logo} alt="logo" />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                    onClick={this.toggleMenu}
                  >
                    <span className="menu_icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse main-menu-item"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a
                          href="/#/"
                          onClick={this.toggleMenu}
                          className="nav-link"
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/#/features"
                          onClick={this.toggleMenu}
                          className="nav-link"
                        >
                          Features
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/#/pricing"
                          onClick={this.toggleMenu}
                          className="nav-link"
                        >
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="/#/contact-us"
                          onClick={this.toggleMenu}
                          className="nav-link"
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a
                    href="https://signup.excelera.com.au"
                    className="d-none d-sm-block btn_1 home_page_btn"
                  >
                    Sign Up
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </header>
        <div className="main-body">{this.props.children}</div>
        <footer className="footer_part">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 col-md-4 col-lg-4">
                <div className="single_footer_part">
                  <a href="/" className="footer_logo_iner">
                    <img className="footer-logo" src={logo} alt />
                  </a>
                  <p>
                    Excelera makes running a small business just a little bit
                    easier. Manage your sales pipeline, invoices, inventory and
                    much more in a single cloud application.
                  </p>
                </div>
              </div>
              <div className="col-sm-8 col-md-4 col-lg-2">
                <div className="single_footer_part">
                  <h4>About Us</h4>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/#/why">Why choose Excelera</a>
                    </li>
                    <li>
                      <a href="/#/contact-us">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-8 col-md-4 col-lg-2">
                <div className="single_footer_part">
                  <h4>Quick Links</h4>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/#/pricing">Pricing</a>
                    </li>
                    <li>
                      <a href="/#/features">Features</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-8 col-md-4 col-lg-2">
                <div className="single_footer_part">
                  <h4>My Account</h4>
                  <ul className="list-unstyled">
                    <li>
                      <a href="https://signup.excelera.com.au">Sign Up</a>
                    </li>
                    <li>
                      <a href="https://logins.excelera.com.au">Sign In</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-8 col-md-4 col-lg-2">
                <div className="single_footer_part">
                  <h4>Resources</h4>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#">Starting a new business</a>
                    </li>
                    <li>
                      <a href="#">What is an ERP</a>
                    </li>
                    <li>
                      <a href="#">What is a CRM</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Layout;
